import stockService from "src/services/stockService";
import { Stock, StockDelivery, StockOut } from "src/interface/stockIf";


export const STOCK_IN = "@stocks/in";
export const STOCK_IN_SUCCESS = "@stocks/in-success";
export const STOCK_IN_FAILED = "@stocks/in-failed";

export const STOCK_EDIT = "@stocks/edit";
export const STOCK_EDIT_SUCCESS = "@stocks/edit-success";
export const STOCK_EDIT_FAILED = "@stocks/edit-failed";

export const STOCK_DELETE = "@stocks/delete";
export const STOCK_DELETE_SUCCESS = "@stocks/delete-success";
export const STOCK_DELETE_FAILED = "@stocks/delete-failed";

export const STOCK_LIST = "@stocks/list";
export const STOCK_LIST_SUCCESS = "@stocks/list-success";
export const STOCK_LIST_FAILED = "@stocks/list-failed";

export const IN_STOCK_LIST = "@stocks/in-list";
export const IN_STOCK_LIST_SUCCESS = "@stocks/in-list-success";
export const IN_STOCK_LIST_FAILED = "@stocks/in-list-failed";

export const STOCK_DELIVER = "@stocks/deliver";
export const STOCK_DELIVER_SUCCESS = "@stocks/deliver-success";
export const STOCK_DELIVER_FAILED = "@stocks/deliver-failed";

export const DELIVERED_STOCK_LIST = "@stocks/delivered-list";
export const DELIVERED_STOCK_LIST_SUCCESS = "@stocks/delivered-list-success";
export const DELIVERED_STOCK_LIST_FAILED = "@stocks/delivered-list-failed";

export const RETURNED_STOCK_LIST = "@stocks/returned-list";
export const RETURNED_STOCK_LIST_SUCCESS = "@stocks/returned-list-success";
export const RETURNED_STOCK_LIST_FAILED = "@stocks/returned-list-failed";

export const STOCK_RETURN = "@stocks/return";
export const STOCK_RETURN_SUCCESS = "@stocks/return-success";
export const STOCK_RETURN_FAILED = "@stocks/return-failed";

export const EMPTY_STOCK_LIST = "@stocks/empty-list";
export const EMPTY_STOCK_LIST_SUCCESS = "@stocks/empty-list-success";
export const EMPTY_STOCK_LIST_FAILED = "@stocks/empty-list-failed";

export const STOCK_OUT = "@stocks/out";
export const STOCK_OUT_SUCCESS = "@stocks/out-success";
export const STOCK_OUT_FAILED = "@stocks/out-failed";

export const STOCK_DETAILS = "@stocks/details";
export const STOCK_DETAILS_SUCCESS = "@stocks/details-success";
export const STOCK_DETAILS_FAILED = "@stocks/details-failed";


export function stockIn(stockInfo) {
    return async (dispatch) => {

        const newStock = new Stock(stockInfo);

        try {
            dispatch({ type: STOCK_IN });

            const stock = await stockService.stockIn(newStock);
            dispatch(fetchStocks());

            dispatch({
                type: STOCK_IN_SUCCESS,
                payload: { stock }
            });
        } catch (err) {
            dispatch({ type: STOCK_IN_FAILED });
            throw err;
        }
    };
}

export function editStock(stockInfo) {
    return async (dispatch) => {

        const newStock = new Stock(stockInfo);

        try {
            dispatch({ type: STOCK_EDIT});

            const stock = await stockService.editStock(newStock);
            dispatch(fetchStocks());

            dispatch({
                type: STOCK_EDIT_SUCCESS,
                payload: { stock }
            });
        } catch (err) {
            dispatch({ type: STOCK_EDIT_FAILED });
            throw err;
        }
    };
}



export function deleteStock(txnId) {
    return async (dispatch) => {
        console.log("deleteStock(A) ", txnId);

        //const newClient = new Client(clientInfo);

        try {
            dispatch({ type: STOCK_DELETE });

            await stockService.deleteStock(txnId);
            dispatch(fetchStocks());

            dispatch({
                type: STOCK_DELETE_SUCCESS,
                payload: { txnId }
            });

        } catch (err) {
            console.log("deleteStock(A) err", err);
            dispatch({ type: STOCK_DELETE_FAILED });
            throw err;
        }
    };
}


export function fetchStocks() {
    return async (dispatch) => {
        try {
            dispatch({ type: STOCK_LIST });
            const stocks = await stockService.fetchStocks();
            console.log("fetchStocks() stocks: ", stocks);
            dispatch({
                type: STOCK_LIST_SUCCESS,
                payload: {
                    stocks,
                },
            });
        } catch (error) {
            console.log("fetchStocks() error: ", error);
            dispatch({ type: STOCK_LIST_FAILED });
            throw error;
        }
    };
}

export function fetchInStocks() {
    return async (dispatch) => {
        try {
            dispatch({ type: IN_STOCK_LIST });
            const stocks = await stockService.fetchInStocks();
            console.log("fetchInStocks() stocks: ", stocks);
            dispatch({
                type: IN_STOCK_LIST_SUCCESS,
                payload: {
                    stocks,
                },
            });
        } catch (error) {
            console.log("fetchInStocks() error: ", error);
            dispatch({ type: IN_STOCK_LIST_FAILED });
            throw error;
        }
    };
}

export function fetchDeliveredStocks(clientId) {
    return async (dispatch) => {
        try {
            dispatch({ type: DELIVERED_STOCK_LIST });
            const stocks = await stockService.fetchDeliveredStocks(clientId);
            console.log("fetchDeliveredStocks() stocks: ", stocks);
            dispatch({
                type: DELIVERED_STOCK_LIST_SUCCESS,
                payload: {
                    stocks,
                },
            });
            return stocks;
        } catch (error) {
            console.log("fetchDeliveredStocks() error: ", error);
            dispatch({ type: DELIVERED_STOCK_LIST_FAILED });
            throw error;
        }
    };
}

export function fetchEmptyStocks() {
    return async (dispatch) => {
        try {
            dispatch({ type: EMPTY_STOCK_LIST });
            const stocks = await stockService.fetchEmptyStocks();
            console.log("fetchEmptyStocks() stocks: ", stocks);
            dispatch({
                type: EMPTY_STOCK_LIST_SUCCESS,
                payload: {
                    stocks,
                },
            });
            return stocks;
        } catch (error) {
            console.log("fetchEmptyStocks() error: ", error);
            dispatch({ type: EMPTY_STOCK_LIST_FAILED });
            throw error;
        }
    };
}

export function fetchReturnedStocks(clientId) {
    return async (dispatch) => {
        try {
            dispatch({ type: RETURNED_STOCK_LIST });
            const stocks = await stockService.fetchReturnedStocks(clientId);
            console.log("fetchReturnedStocks() stocks: ", stocks);
            dispatch({
                type: RETURNED_STOCK_LIST_SUCCESS,
                payload: {
                    stocks,
                },
            });
            return stocks;
        } catch (error) {
            console.log("fetchReturnedStocks() error: ", error);
            dispatch({ type: RETURNED_STOCK_LIST_FAILED });
            throw error;
        }
    };
}

export function fetchStockDetails(txnId) {
    return async (dispatch) => {
        try {
            dispatch({ type: STOCK_DETAILS });
            const stock = await stockService.fetchStockDetails(txnId);
            console.log("fetchStockDetails() txnId: ", txnId, stock);
            dispatch({
                type: STOCK_DETAILS_SUCCESS,
                payload: {
                    stock,
                },
            });
            return stock;
        } catch (error) {
            console.log("fetchStockDetails() error: ", error);
            dispatch({ type: STOCK_DETAILS_FAILED });
            throw error;
        }
    };
}

export function deliverStock(deliveryInfo) {
    return async (dispatch) => {

        const newDeliveryData = new StockDelivery(deliveryInfo);

        try {
            dispatch({ type: STOCK_DELIVER });

            const stock = await stockService.deliverStock(newDeliveryData);
            dispatch(fetchStocks());

            dispatch({
                type: STOCK_DELIVER_SUCCESS,
                payload: { stock }
            });
        } catch (err) {
            dispatch({ type: STOCK_DELIVER_FAILED });
            throw err;
        }
    };
}

export function returnStock(deliveryInfo) {
    return async (dispatch) => {

        const newDeliveryData = new StockDelivery(deliveryInfo);

        try {
            dispatch({ type: STOCK_RETURN });

            const stock = await stockService.returnStock(newDeliveryData);
            dispatch(fetchStocks());

            dispatch({
                type: STOCK_RETURN_SUCCESS,
                payload: { stock }
            });
        } catch (err) {
            dispatch({ type: STOCK_RETURN_FAILED });
            throw err;
        }
    };
}

export function outStock(deliveryInfo) {
    return async (dispatch) => {

        const newOutData = new StockOut(deliveryInfo);

        try {
            dispatch({ type: STOCK_OUT });

            const stock = await stockService.outStock(newOutData);
            dispatch(fetchStocks());

            dispatch({
                type: STOCK_OUT_SUCCESS,
                payload: { stock }
            });
        } catch (err) {
            dispatch({ type: STOCK_OUT_FAILED });
            throw err;
        }
    };
}