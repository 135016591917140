import axios from "src/utils/axios";

import {
  UPLOAD_ASSEMBLY_MASTER_URL,
  FETCH_ASSEMBLY_MASTER_URL,
  UPLOAD_ASSEMBLY_MASTER_CATEGORY_URL,
  FETCH_ASSEMBLY_MASTER_CATEGORY_URL,
} from "src/constants";

class MasterService {
  uploadAssemblyMaster = (assemblyCategories) =>
    new Promise((resolve, reject) => {
      const payload = assemblyCategories;
      console.log(
        "MasterService.uploadAssemblyMaster:" +
          JSON.stringify(assemblyCategories)
      );
      axios
        .post(UPLOAD_ASSEMBLY_MASTER_URL, { payload })
        .then((response) => {
          // console.log("MasterService(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  fetchAssemblyMasterList = () =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "MasterService.fetchAssemblyCategories:");
      axios
        .get(FETCH_ASSEMBLY_MASTER_URL)
        .then((response) => {
          // console.log("MasterService.fetchAssemblyCategories(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let list = response.data.payload.assemblyMasterList;
            // console.log("MasterService.fetchAssemblyCategories List:", list);
            resolve(list);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  uploadAssemblyCategories = (assemblyCategories) =>
    new Promise((resolve, reject) => {
      const payload = assemblyCategories;
      console.log(
        "MasterService.uploadAssemblyCategories:" +
          JSON.stringify(assemblyCategories)
      );
      axios
        .post(UPLOAD_ASSEMBLY_MASTER_CATEGORY_URL, { payload })
        .then((response) => {
          // console.log("MasterService(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  fetchAssemblyCategories = () =>
    new Promise((resolve, reject) => {
      // console.log(
      //   "MasterService.fetchAssemblyCategories:");
      axios
        .get(FETCH_ASSEMBLY_MASTER_CATEGORY_URL)
        .then((response) => {
          // console.log("MasterService.fetchAssemblyCategories(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let list = response.data.payload.assemblyCategories;
            // console.log("MasterService.fetchAssemblyCategories List:", list);
            resolve(list);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });
}

const masterService = new MasterService();

export default masterService;
