import axios from "src/utils/axios";

import {
  CREATE_PROJECT_URL,
  UPDATE_PROJECT_URL,
  UPDATE_PROPERTY_INFO_URL,
  CREATE_ASSET_URL,
  UPDATE_ASSET_URL,
  DELETE_ASSET_URL,
  CREATE_TENANT_URL,
  CREATE_PROJECT_ANALYSIS_URL,
  DELETE_PROJECT_ANALYSIS_URL,
  UPLOAD_PROJECT_RSM_URL,
  ADD_RSM_TO_PROJECT_ANALYSIS_URL,
  UPLOAD_PROJECT_CPC_URL,
  ADD_CPC_TO_PROJECT_ANALYSIS_URL,
  UPLOAD_PROJECT_INDIRECTS_URL
} from "src/constants";

class ProjectService {
  createProject = (newProject) =>
    new Promise((resolve, reject) => {
      const payload = newProject;

      console.log("ProjectService.createProject:" + JSON.stringify(payload));

      axios
        .post(CREATE_PROJECT_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));

          if (response.data.payload) {
            let projects = response.data.payload;
            resolve(projects);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.log(
            "ProjectService.createService (Response Error):" +
              JSON.stringify(error)
          );
          reject(error);
        });
    });

  updateProject = (editProject) =>
    new Promise((resolve, reject) => {
      const payload = editProject;

      console.log("ProjectService.updateService:" + JSON.stringify(payload));

      axios
        .post(UPDATE_PROJECT_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));

          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  updatePropertyInfo = (propertyInfo) =>
    new Promise((resolve, reject) => {
      const payload = propertyInfo;

      console.log(
        "ProjectService.updatePropertyInfo:" + JSON.stringify(payload)
      );

      axios
        .post(UPDATE_PROPERTY_INFO_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));

          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  createAsset = (asset) =>
    new Promise((resolve, reject) => {
      const payload = asset;
      console.log("ProjectService.createAsset:" + JSON.stringify(payload));
      axios
        .post(CREATE_ASSET_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  updateAsset = (asset) =>
    new Promise((resolve, reject) => {
      const payload = asset;

      console.log("ProjectService.updateAsset:" + JSON.stringify(payload));

      axios
        .post(UPDATE_ASSET_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));

          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

    deleteAsset = (payload) =>
    new Promise((resolve, reject) => {

      console.log("ProjectService.deleteAsset:" + JSON.stringify(payload));

      axios
        .post(DELETE_ASSET_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));

          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.deleteAsset (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  createAssetTenant = (tenant) =>
    new Promise((resolve, reject) => {
      const payload = tenant;
      console.log(
        "ProjectService.createAssetTenant:" + JSON.stringify(payload)
      );
      axios
        .post(CREATE_TENANT_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  createProjectAnalysis = (analysis) =>
    new Promise((resolve, reject) => {
      const payload = analysis;
      console.log(
        "ProjectService.createProjectAnalysis:" + JSON.stringify(analysis)
      );
      axios
        .post(CREATE_PROJECT_ANALYSIS_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));
          resolve(response);
          // if (response.data.payload) {
          //   let projectAnalysis = response.data.payload;
          //   resolve(projectAnalysis);
          // } else {
          //   reject(response.data.error);
          // }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

    deleteProjectAnalysis = (analysisInfo) =>
      new Promise((resolve, reject) => {
        const payload = analysisInfo;
        console.log(
          "ProjectService.deleteProjectAnalysis:" + JSON.stringify(analysisInfo)
        );
        axios
          .post(DELETE_PROJECT_ANALYSIS_URL, { payload })
          .then((response) => {
            console.log("ProjectService(Response):",response);

            resolve(response?.data);
            // if (!response.data.error) {
            //   let projectAnalysis = response.data.payload;
            //   resolve(projectAnalysis);
            // } else {
            //   reject(response.data.message);
            // }
          })
          .catch((error) => {
            //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
            reject(error);
          });
      });

  addRSMDataToProjectAnalysis = (analysis) =>
    new Promise((resolve, reject) => {
      const payload = analysis;
      console.log(
        "ProjectService.addRSMDataToProjectAnalysis:" + JSON.stringify(analysis)
      );
      axios
        .post(ADD_RSM_TO_PROJECT_ANALYSIS_URL, { payload })
        .then((response) => {
          console.log(
            "ProjectService.addRSMDataToProjectAnalysis: Response):" +
              JSON.stringify(response)
          );
          if (response.data.payload) {
            let projectAnalysis = response.data.payload;
            resolve(projectAnalysis);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.addRSMDataToProjectAnalysis (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  uploadRSM = (rsmList) =>
    new Promise((resolve, reject) => {
      const payload = rsmList;
      console.log("ProjectService.uploadRSM:" + JSON.stringify(rsmList));
      axios
        .post(UPLOAD_PROJECT_RSM_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.createService (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  uploadCPC = (cpcList) =>
    new Promise((resolve, reject) => {
      const payload = cpcList;
      console.log("ProjectService.uploadCPC:" + JSON.stringify(cpcList));
      axios
        .post(UPLOAD_PROJECT_CPC_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.uploadCPC (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

  addCPCDataToProjectAnalysis = (analysis) =>
    new Promise((resolve, reject) => {
      const payload = analysis;
      console.log(
        "ProjectService.addCPCDataToProjectAnalysis:" + JSON.stringify(analysis)
      );
      axios
        .post(ADD_CPC_TO_PROJECT_ANALYSIS_URL, { payload })
        .then((response) => {
          console.log(
            "ProjectService.addCPCDataToProjectAnalysis: Response):" +
              JSON.stringify(response)
          );
          if (response.data.payload) {
            let projectAnalysis = response.data.payload;
            resolve(projectAnalysis);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.addCPCDataToProjectAnalysis (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });

    uploadIndirects = (indirectsList) =>
    new Promise((resolve, reject) => {
      const payload = indirectsList;
      console.log("ProjectService.uploadCPC:" + JSON.stringify(indirectsList));
      axios
        .post(UPLOAD_PROJECT_INDIRECTS_URL, { payload })
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));
          if (response.data.payload) {
            let project = response.data.payload;
            resolve(project);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ProjectService.uploadCPC (Response Error):" + JSON.stringify(error));
          reject(error);
        });
    });



  fetchProjectInfo = (businessId, userId) =>
    new Promise((resolve, reject) => {
      const payload = { businessId: businessId, userId: userId };

      console.log("ProjectService.fetchProjectInfo:" + JSON.stringify(payload));

      axios
        .get("/api/management/project/projects")
        .then((response) => {
          console.log("ProjectService(Response):" + JSON.stringify(response));

          if (response.data.payload) {
            let projects = response.data.payload;
            resolve(projects);
          } else {
            resolve(null);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.log(
            "MgmtClassesService.fetchClassInfo (Response Error):" +
              JSON.stringify(error)
          );
          resolve(null);
          reject(error);
        });
    });
}

const mgmtProjectService = new ProjectService();

export default mgmtProjectService;
