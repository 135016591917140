import clientService from "src/services/clientService";
import { Client } from "src/interface/clientIf";


export const CLIENT_CREATE = "@clients/create";
export const CLIENT_CREATE_SUCCESS = "@clients/create-success";
export const CLIENT_CREATE_FAILED = "@clients/create-failed";

export const CLIENT_EDIT = "@clients/edit";
export const CLIENT_EDIT_SUCCESS = "@clients/edit-success";
export const CLIENT_EDIT_FAILED = "@clients/edit-failed";

export const CLIENT_DELETE = "@clients/delete";
export const CLIENT_DELETE_SUCCESS = "@clients/delete-success";
export const CLIENT_DELETE_FAILED = "@clients/delete-failed";

export const CLIENT_LIST = "@clients/list";
export const CLIENT_LIST_SUCCESS = "@clients/list-success";
export const CLIENT_LIST_FAILED = "@clients/list-failed";


export function createClient(clientInfo) {
    return async (dispatch) => {

        const newClient = new Client(clientInfo);

        try {
            dispatch({ type: CLIENT_CREATE });

            const client = await clientService.createClient(newClient);
            dispatch(fetchClients());

            dispatch({
                type: CLIENT_CREATE_SUCCESS,
                payload: { client }
            });
        } catch (err) {
            dispatch({ type: CLIENT_CREATE_FAILED });
            throw err;
        }
    };
}

export function editClient(clientInfo) {
    return async (dispatch) => {

        const newClient = new Client(clientInfo);

        try {
            dispatch({ type: CLIENT_EDIT});

            const client = await clientService.editClient(newClient);
            dispatch(fetchClients());

            dispatch({
                type: CLIENT_EDIT_SUCCESS,
                payload: { client }
            });
        } catch (err) {
            dispatch({ type: CLIENT_EDIT_FAILED });
            throw err;
        }
    };
}



export function deleteClient(clientId) {
    return async (dispatch) => {
        console.log("deleteClient(A) ", clientId);

        //const newClient = new Client(clientInfo);

        try {
            dispatch({ type: CLIENT_DELETE });

            await clientService.deleteClient(clientId);
            dispatch(fetchClients());

            dispatch({
                type: CLIENT_DELETE_SUCCESS,
                payload: { clientId }
            });

        } catch (err) {
            console.log("deleteClient(A) err", err);
            dispatch({ type: CLIENT_DELETE_FAILED });
            throw err;
        }
    };
}




export function fetchClients() {
    return async (dispatch) => {
        try {
            dispatch({ type: CLIENT_LIST });
            const clients = await clientService.fetchClients();
            console.log("fetchClients() clients: ", clients);
            dispatch({
                type: CLIENT_LIST_SUCCESS,
                payload: {
                    clients,
                },
            });
        } catch (error) {
            console.log("fetchClients() error: ", error);
            dispatch({ type: CLIENT_LIST_FAILED });
            throw error;
        }
    };
}