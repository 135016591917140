/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  PROJECT_CREATE,
  PROJECT_CREATE_SUCCESS,
  PROJECT_UPDATE_SUCCESS,
  ASSET_CREATE_SUCCESS,
  TENANT_CREATE_SUCCESS,
  PROJECT_CREATE_FAILURE,
  PROJECT_UPDATE_FAILURE,
  ASSET_CREATE_FAILURE,
  TENANT_CREATE_FAILURE,
  PROJECT_RSM_UPLOAD_SUCCESS,
  PROJECT_CPC_UPLOAD_SUCCESS,
} from "src/actions/projectAction";

const initialState = {
  project: {},
};

const projectReducer = (state = initialState, action) => {
  // console.log("assemblyReducer: action.type: ", action.type, "payload: ", action.payload);
  switch (action.type) {
    case PROJECT_CREATE: {
      return produce(state, (draft) => {
        draft.project = {};
      });
    }

    case PROJECT_CREATE_SUCCESS:
    case PROJECT_UPDATE_SUCCESS:
    case ASSET_CREATE_SUCCESS:
    case TENANT_CREATE_SUCCESS: 
    case PROJECT_RSM_UPLOAD_SUCCESS:        
    case PROJECT_CPC_UPLOAD_SUCCESS:
    {
      const project = action.payload.project;

      console.log("projectReducer: ", action.type,  action.payload);
      return produce(state, (draft) => {
        draft.project = project;
      });
    }

    case PROJECT_CREATE_FAILURE:
    case PROJECT_UPDATE_FAILURE:
    case ASSET_CREATE_FAILURE:
    case TENANT_CREATE_FAILURE: {
      return produce(state, (draft) => {
        // Maybe store error
        draft.project = {};
      });
    }

    default: {
      return state;
    }
  }
};

export default projectReducer;
