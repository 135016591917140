import projectService from 'src/services/projectService';

import { Project, PropertyInfo, Asset, AssetDeleteInfo, Tenant, ProjectAnalysis, DeleteProjectAnalysisInfo, RSMForProjectAnalysis, RSMList, CPCList, CPCForProjectAnalysis, IndirectsList } from 'src/interface/projectIf';

export const PROJECTS_INFO ='@project/info';
export const PROJECT_CREATE ='@project/create';
export const PROJECT_CREATE_SUCCESS ='@project/create-success';
export const PROJECT_CREATE_FAILURE ='@project/create-failure';

export const PROJECT_UPDATE = '@project/update';
export const PROJECT_UPDATE_SUCCESS = '@project/update-success';
export const PROJECT_UPDATE_FAILURE = '@project/update-failure';

export const PROPERTY_INFO_UPDATE = '@project/property-info/update';
export const PROPERTY_INFO_UPDATE_SUCCESS = '@project/property-info/update-success';
export const PROPERTY_INFO_UPDATE_FAILURE = '@project/property-info/update-failure';

export const ASSET_CREATE ='@asset/create';
export const ASSET_CREATE_SUCCESS ='@asset/create-success';
export const ASSET_CREATE_FAILURE ='@asset/create-failure';

export const ASSET_UPDATE = '@asset/update';
export const ASSET_UPDATE_SUCCESS = '@asset/update-success';
export const ASSET_UPDATE_FAILURE = '@asset/update-failure';

export const ASSET_DELETE = '@asset/delete';
export const ASSET_DELETE_SUCCESS = '@asset/delete-success';
export const ASSET_DELETE_FAILURE = '@asset/delete-failure';



export const TENANT_CREATE ='@tenant/create';
export const TENANT_CREATE_SUCCESS ='@tenant/create-success';
export const TENANT_CREATE_FAILURE ='@tenant/create-failure';


export const PROJECT_ANALYSIS_CREATE = '@project/analysis/create';
export const PROJECT_ANALYSIS_CREATE_SUCCESS = '@project/analysis/create-success';
export const PROJECT_ANALYSIS_CREATE_FAILURE = '@project/analysis/create-failure';

export const PROJECT_ANALYSIS_DELETE = '@project/analysis/delete';
export const PROJECT_ANALYSIS_DELETE_SUCCESS = '@project/analysis/delete-success';
export const PROJECT_ANALYSIS_DELETE_FAILURE = '@project/analysis/delete-failure';


export const RSM_FOR_PROJECT_ANALYSIS_CREATE = '@rsm/project/analysis/create';
export const RSM_FOR_PROJECT_ANALYSIS_CREATE_SUCCESS = '@rsm/project/analysis/create-success';
export const RSM_FOR_PROJECT_ANALYSIS_CREATE_FAILURE = '@rsm/project/analysis/create-failure';

export const PROJECT_RSM_UPLOAD = '@project/rsm/upload';
export const PROJECT_RSM_UPLOAD_SUCCESS = '@project/rsm/upload-success';
export const PROJECT_RSM_UPLOAD_FAILURE = '@project/rsm/upload-failure';

export const PROJECT_CPC_UPLOAD = '@project/cpc/upload';
export const PROJECT_CPC_UPLOAD_SUCCESS = '@project/cpc/upload-success';
export const PROJECT_CPC_UPLOAD_FAILURE = '@project/cpc/upload-failure';

export const CPC_FOR_PROJECT_ANALYSIS_CREATE = '@cpc/project/analysis/create';
export const CPC_FOR_PROJECT_ANALYSIS_CREATE_SUCCESS = '@cpc/project/analysis/create-success';
export const CPC_FOR_PROJECT_ANALYSIS_CREATE_FAILURE = '@cpc/project/analysis/create-failure';

export const PROJECT_INDIRECTS_UPLOAD = '@project/indirects/upload';
export const PROJECT_INDIRECTS_UPLOAD_SUCCESS = '@project/indirects/upload-success';
export const PROJECT_INDIRECTS_UPLOAD_FAILURE = '@project/indirects/upload-failure';


export function createProject(userId, projectInfo) {
  return async (dispatch) => {
    console.log("createProject():", userId, projectInfo );
    let newProject = new Project(userId, projectInfo);
    newProject.addedBy = userId;

    console.log("createProject():" + JSON.stringify(projectInfo) + " " + JSON.stringify(newProject));

    try {
      dispatch({ type: PROJECT_CREATE });

      const project = await projectService.createProject(newProject);

      console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: PROJECT_CREATE_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: PROJECT_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateProject(userId, projectId, projectInfo) {
  return async (dispatch) => {
    console.log("updateProject():", userId, projectId, projectInfo );

    let newProject = new Project(userId, projectInfo);
    newProject.projectId = projectId;
    newProject.updatedBy = userId;

    console.log("createProject():" + JSON.stringify(projectInfo) + " " + JSON.stringify(newProject));

    try {
      dispatch({ type: PROJECT_UPDATE });

      const project = await projectService.updateProject(newProject);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: PROJECT_UPDATE_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: PROJECT_UPDATE_FAILURE });
      throw error;
    }
  };
}


export function updatePropertyInfo(userId, projectId, projectInfo) {
  return async (dispatch) => {
    console.log("updatePropertyInfo():", userId, projectId, projectInfo );

    let newPropertyInfo = new PropertyInfo(userId, projectId, projectInfo);
  //  newPropertyInfo.projectId = projectId;
    newPropertyInfo.updatedBy = userId;

    console.log("updatePropertyInfo():" + JSON.stringify(projectInfo) + " " + JSON.stringify(newPropertyInfo));

    try {
      dispatch({ type: PROPERTY_INFO_UPDATE });

      const project = await projectService.updatePropertyInfo(newPropertyInfo);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: PROPERTY_INFO_UPDATE_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: PROPERTY_INFO_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function createAsset(userId, projectId, assetInfo) {
  return async (dispatch) => {
    console.log("createAsset():", userId, projectId, assetInfo );

    let newAsset = new Asset(userId, projectId, assetInfo);
    newAsset.updatedBy = userId;

    console.log("createAsset():" + JSON.stringify(assetInfo) + " " + JSON.stringify(newAsset));

    try {
      dispatch({ type: ASSET_CREATE });

      const project = await projectService.createAsset(newAsset);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: ASSET_CREATE_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: ASSET_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateAsset(userId, projectId, assetInfo) {
  return async (dispatch) => {
    console.log("updateAsset():", userId, projectId, assetInfo );

    let newAsset = new Asset(userId, projectId, assetInfo);
    newAsset.updatedBy = userId;

    console.log("updateAsset():" + JSON.stringify(assetInfo) + " " + JSON.stringify(newAsset));

    try {
      dispatch({ type: ASSET_UPDATE });

      const project = await projectService.updateAsset(newAsset);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: ASSET_UPDATE_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: ASSET_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function deleteAsset(userId, projectId, assetId, id) {
  return async (dispatch) => {
    console.log("deleteAsset():", userId, projectId, assetId, id );

    let payload = new AssetDeleteInfo(userId, projectId, assetId, id);

    console.log("deleteAsset():", payload);

    try {
      dispatch({ type: ASSET_DELETE });

      const project = await projectService.deleteAsset(payload);

      //console.log("deleteAsset()(after):" + JSON.stringify(project));
      dispatch({
        type: ASSET_DELETE_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: ASSET_DELETE_FAILURE });
      throw error;
    }
  };
}

export function createAssetTenant(userId, projectId, assetId, tenantInfo) {
  return async (dispatch) => {
    console.log("createAssetTenant():", userId, projectId, assetId, tenantInfo );

    let newTenant = new Tenant(userId, projectId, assetId, tenantInfo);
    newTenant.updatedBy = userId;

    console.log("createAssetTenant():" + JSON.stringify(tenantInfo) + " " + JSON.stringify(newTenant));

    try {
      dispatch({ type: TENANT_CREATE });

      const project = await projectService.createAssetTenant(newTenant);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: TENANT_CREATE_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: TENANT_CREATE_FAILURE });
      throw error;
    }
  };
}

export function createProjectAnalysis(userId, projectId, assetId, tenantId, analysisInfo) {
  return async (dispatch) => {
    console.log("createProjectAnalysis():", userId, projectId, assetId, tenantId,analysisInfo );

    let newProjectAnalysis = new ProjectAnalysis(userId, projectId, assetId, tenantId, analysisInfo);
    newProjectAnalysis.updatedBy = userId;

    console.log("createProjectAnalysis():" + JSON.stringify(analysisInfo) + " " + JSON.stringify(newProjectAnalysis));

    try {
      dispatch({ type: PROJECT_ANALYSIS_CREATE });

      const projectAnalysis = await projectService.createProjectAnalysis(newProjectAnalysis);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: PROJECT_ANALYSIS_CREATE_SUCCESS,
        payload: {
          projectAnalysis
        }
      });
    } catch (error) {
      dispatch({ type: PROJECT_ANALYSIS_CREATE_FAILURE });
      throw error;
    }
  };
}

export function deleteProjectAnalysis(analysisId, projectId, assetId, tenantId, userId) {
  return async (dispatch) => {
    console.log("createProjectAnalysis():", userId, projectId, assetId, tenantId, analysisId );

    let newProjectAnalysis = new DeleteProjectAnalysisInfo(analysisId, projectId, assetId, tenantId, userId);
    newProjectAnalysis.updatedBy = userId;

    console.log("createProjectAnalysis():" + analysisId + " " + JSON.stringify(newProjectAnalysis));

    try {
      dispatch({ type: PROJECT_ANALYSIS_DELETE });

      const projectAnalysis = await projectService.deleteProjectAnalysis(newProjectAnalysis);

      console.log("deleteProjectAnalysis()(after):" , projectAnalysis);
      dispatch({
        type: PROJECT_ANALYSIS_DELETE_SUCCESS,
        payload: {
          projectAnalysis
        }
      });

    } catch (error) {
      dispatch({ type: PROJECT_ANALYSIS_DELETE_FAILURE });
      throw error;
    }
  };
}





export function addRSMDataToProjectAnalysis(userId, projectId, assetId, tenantId, analysisInfo) {
  return async (dispatch) => {
    console.log("addRSMDataToProjectAnalysis():", userId, projectId, assetId, tenantId,analysisInfo );

    let newProjectAnalysis = new RSMForProjectAnalysis(userId, projectId, assetId, tenantId, analysisInfo);
    newProjectAnalysis.updatedBy = userId;

    console.log("addRSMDataToProjectAnalysis():" + JSON.stringify(analysisInfo) + " " + JSON.stringify(newProjectAnalysis));

    try {
      dispatch({ type: RSM_FOR_PROJECT_ANALYSIS_CREATE });

      const projectAnalysis = await projectService.addRSMDataToProjectAnalysis(newProjectAnalysis);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: RSM_FOR_PROJECT_ANALYSIS_CREATE_SUCCESS,
        payload: {
          projectAnalysis
        }
      });
    } catch (error) {
      dispatch({ type: RSM_FOR_PROJECT_ANALYSIS_CREATE_FAILURE });
      throw error;
    }
  };
}


export function uploadRSM(userId, projectId, assetId, tenantId, assetName, tenantName, rsmList) {
  return async (dispatch) => {
    console.log("uploadRSM():", userId, rsmList);

    let newRSMList = new RSMList(userId, projectId, assetId, tenantId, assetName, tenantName, rsmList);
    newRSMList.updatedBy = userId;

    console.log("uploadRSM():" + JSON.stringify(rsmList) + " " + JSON.stringify(newRSMList));

    try {
      dispatch({ type: PROJECT_RSM_UPLOAD });

      const project = await projectService.uploadRSM(newRSMList);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: PROJECT_RSM_UPLOAD_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: PROJECT_RSM_UPLOAD_FAILURE });
      throw error;
    }
  };
}

export function uploadCPC(userId, projectId, assetId, tenantId, assetName, tenantName, cpcList) {
  return async (dispatch) => {
    console.log("uploadCPC():", userId, cpcList);

    let newCPCList = new CPCList(userId, projectId, assetId, tenantId, assetName, tenantName, cpcList);
    newCPCList.updatedBy = userId;

    console.log("uploadCPC():" + JSON.stringify(cpcList) + " " + JSON.stringify(newCPCList));

    try {
      dispatch({ type: PROJECT_CPC_UPLOAD });

      const project = await projectService.uploadCPC(newCPCList);

      //console.log("uploadCPC()(after):" + JSON.stringify(project));
      dispatch({
        type: PROJECT_CPC_UPLOAD_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: PROJECT_CPC_UPLOAD_FAILURE });
      throw error;
    }
  };
}

export function addCPCDataToProjectAnalysis(userId, projectId, assetId, tenantId, analysisInfo) {
  return async (dispatch) => {
    console.log("addCPCDataToProjectAnalysis():", userId, projectId, assetId, tenantId,analysisInfo );

    let newProjectAnalysis = new CPCForProjectAnalysis(userId, projectId, assetId, tenantId, analysisInfo);
    newProjectAnalysis.updatedBy = userId;

    console.log("addCPCDataToProjectAnalysis():" + JSON.stringify(analysisInfo) + " " + JSON.stringify(newProjectAnalysis));

    try {
      dispatch({ type: CPC_FOR_PROJECT_ANALYSIS_CREATE });

      const projectAnalysis = await projectService.addCPCDataToProjectAnalysis(newProjectAnalysis);

      //console.log("addCPCDataToProjectAnalysis()(after):" + JSON.stringify(project));
      dispatch({
        type: CPC_FOR_PROJECT_ANALYSIS_CREATE_SUCCESS,
        payload: {
          projectAnalysis
        }
      });
    } catch (error) {
      dispatch({ type: CPC_FOR_PROJECT_ANALYSIS_CREATE_FAILURE });
      throw error;
    }
  };
}

export function uploadIndirects(userId, projectId, indirectsList) {
  return async (dispatch) => {
    console.log("uploadIndirects():", userId, indirectsList);

    let newIndirectsList = new IndirectsList(userId, projectId, indirectsList);
    newIndirectsList.updatedBy = userId;

    console.log("uploadIndirects():" + JSON.stringify(indirectsList) + " " + JSON.stringify(newIndirectsList));

    try {
      dispatch({ type: PROJECT_INDIRECTS_UPLOAD });

      const project = await projectService.uploadIndirects(newIndirectsList);

      dispatch({
        type: PROJECT_INDIRECTS_UPLOAD_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: PROJECT_INDIRECTS_UPLOAD_FAILURE });
      throw error;
    }
  };
}


export function setProjectData(projects) {
  return (dispatch) => dispatch({
    type: PROJECTS_INFO,
    payload: {
      projects
    }
  });
}
