class UserProfile {
  constructor(userInfo){
    //console.log('NewBusinessReqInfo: ' + JSON.stringify(userInfo));
    this.id = userInfo.id;
    this.email = userInfo.email;
    this.mobile = userInfo.mobile;
    this.name = userInfo.name;
    this.dob = userInfo.dob;
    this.role = userInfo.role;
    this.avatar = userInfo.avatar;
  }

}

class NewUserReqInfo {
  constructor(userInfo){
    this.email = userInfo.email;
    this.mobile = userInfo.mobile;
    this.name = userInfo.name;
    this.password = userInfo.password;
  }

}


class UserProfileUpdate {
  constructor(email, info){
    //console.log("UserProfileUpdate:", businessId, email, info);
    this.email = info.email;
    this.mobile = info.mobile;
    this.name = info.name;
    this.dob = info.dob;
    this.role = info.role;
    this.avatar = info.avatar;
  }
}

export default { UserProfile };
export { UserProfile, NewUserReqInfo, UserProfileUpdate};
