import { lazy } from 'react';


import AuthGuard from "src/components/AuthGuard";

// project imports
import DashboardLayout from "src/layouts/DashboardLayout";
import Loadable from 'src/components/Loadable';

// dashboard routing
const ClientsListView = Loadable(lazy(() => import('src/views/management/client/ClientsList')));
const ClientCreateView = Loadable(lazy(() => import('src/views/management/client/ClientCreate')));
const ClientEditView = Loadable(lazy(() => import('src/views/management/client/ClientEdit')));
const ClientDetailsView = Loadable(lazy(() => import('src/views/management/client/ClientDetails')));

const SupplierListView = Loadable(lazy(() => import('src/views/management/supplier/SupplierList')));

const StocksListView = Loadable(lazy(() => import('src/views/management/stock/StocksList')));
const StockInView = Loadable(lazy(() => import('src/views/management/stock/StockCreate')));
const StockEditView = Loadable(lazy(() => import('src/views/management/stock/StockEdit')))
const StockDeliveryView = Loadable(lazy(() => import('src/views/management/stock/StockDelivery')));
const StockReturnView = Loadable(lazy(() => import('src/views/management/stock/StockReturn')));
const StockOutView = Loadable(lazy(() => import('src/views/management/stock/StockOut')));
const StockDetailsView = Loadable(lazy(() => import('src/views/management/stock/StockDetails')))

const DashboardDefault = Loadable(lazy(() => import('src/views/dashboards/DashboardView')));
// const ProductListView = Loadable(lazy(() => import('src/views/management/products/ProductListView')));
// const ProductCreateView = Loadable(lazy(() => import('src/views/management/products/ProductCreateView')));
// const ProductEditView = Loadable(lazy(() => import('src/views/management/products/ProductEditView')));
// const ProjectListView = Loadable(lazy(() => import('src/views/management/projects/ProjectListView')));
// const ProjectCreateView = Loadable(lazy(() => import('src/views/management/projects/ProjectCreateView')));
// const AssetView = Loadable(lazy(() => import('src/views/management/projects/AssetView')));
// const ProjectView = Loadable(lazy(() => import('src/views/management/projects/ProjectView')));
// const AssemblyMasterCategoryView = Loadable(lazy(() => import('src/views/management/master/AssemblyMasterCategoryView')));
// const AssemblyMasterView = Loadable(lazy(() => import('src/views/management/master/AssemblyMasterView')));
// const ProjectReportView = Loadable(lazy(() => import('src/views/management/projects/ProjectReportView')));

// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
    path: '/app',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
        {
            path: '/app',
            element: <DashboardDefault />
        },
        {
            path: '/app/management/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/app/management/clients',
            element: <ClientsListView />
        },
        {
            path: '/app/management/clients/create',
            element: <ClientCreateView />
        },
        {
            path: '/app/management/clients/edit',
            element: <ClientEditView />
        },
        {
            path: '/app/management/clients/detail',
            element: <ClientDetailsView />
        },
        {
            path: '/app/management/suppliers',
            element: <SupplierListView />
        },
        {
            path: '/app/management/stocks',
            element: <StocksListView />
        },
        {
            path: '/app/management/stocks/in',
            element: <StockInView />
        },
        {
            path: '/app/management/stocks/edit',
            element: <StockEditView />
        },
        {
            path: '/app/management/stocks/delivery',
            element: <StockDeliveryView />
        },
        {
            path: '/app/management/stocks/return',
            element: <StockReturnView />
        },
        {
            path: '/app/management/stocks/out',
            element: <StockOutView />
        },
        {
            path: '/app/management/stocks/detail',
            element: <StockDetailsView />
        },

        // {
        //     path: '/app/management/projects',
        //     element: <ProjectListView />
        // },
        // {
        //     path: '/app/management/projects/create',
        //     element: <ProjectCreateView/>
        // },
        // {
        //     path: '/app/management/project',
        //     element: <ProjectView/>
        // },
        // {
        //     path: '/app/management/dashboard',
        //     element: <DashboardDefault />
        // },
        // {
        //     path: '/app/management/assets/create',
        //     element: <AssetView/>
        // },
        // {
        //     path: "/app/management/project/assets",
        //     element: <DashboardDefault />
        // },
        // {
        //     path: "/app/management/analysis/inputs",
        //     element: <DashboardDefault />
        // },
        // {
        //     path: "/app/management/analysis/calculation",
        //     element: <DashboardDefault />
        // },
        // {
        //     path: "/app/management/master/assembly/categories",
        //     element: <AssemblyMasterCategoryView />
        // },
        // {
        //     path: "/app/management/master/assembly/masters",
        //     element: <AssemblyMasterView />
        // },
        // {
        //     path: "/app/management/reports/schedules",
        //     element: <DashboardDefault />
        // },
        // {
        //     path: "/app/management/reports/reports",
        //     element: <ProjectReportView />
        // },
        // {
        //     path: "/app/account",
        //     element: <DashboardDefault />
        // },

        // {
        //     path: "/app/management/catalog/product/edit/:productId",
        //     element: <ProductEditView />
        // }
    ]
};

export default DashboardRoutes;
