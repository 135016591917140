import { lazy } from 'react';

// project imports
import Loadable from 'src/components/Loadable';
import MinimalLayout from 'src/layouts/MinimalLayout';

// login option 3 routing
//const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
//const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));


const SignIn = Loadable(lazy(() => import("src/views/auth/SignInView")));
const SignUp = Loadable(lazy(() => import('src/views/auth/SignUpView')));
const ErrorPage = Loadable(lazy(() => import("src/views/pages/Error404View")));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/signin',
            element: <SignIn />
        },
        {
            path: '/signup',
            element: <SignUp />
        },
      //  {
      //    path: '/',
      //    element: () => <Navigate to "/home" />
      //  },
        {
          path: '/404',
          element: <ErrorPage />
        }
    ]
};

export default AuthRoutes;
