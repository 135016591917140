import masterService from 'src/services/masterService';

import { AssemblyCategories, AssemblyMaster } from 'src/interface/masterIf';

export const ASSEMBLY_MASTER_UPLOAD = '@assembly-master/upload';
export const ASSEMBLY_MASTER_UPLOAD_SUCCESS = '@assembly-master/upload-success';
export const ASSEMBLY_MASTER_UPLOAD_FAILURE = '@assembly-master/upload-failure';

export const ASSEMBLY_MASTER_FETCH = '@assembly-master/fetch';
export const ASSEMBLY_MASTER_FETCH_SUCCESS = '@assembly-master/fetch-success';
export const ASSEMBLY_MASTER_FETCH_FAILURE = '@assembly-master/fetch-failure';

export const PROJECT_ASSEMBLY_MASTER_CATEGORY_UPLOAD = '@project/assembly-category/upload';
export const PROJECT_ASSEMBLY_MASTER_CATEGORY_UPLOAD_SUCCESS = '@project/assembly-category/upload-success';
export const PROJECT_ASSEMBLY_MASTER_CATEGORY_UPLOAD_FAILURE = '@project/assembly-category/upload-failure';

export const ASSEMBLY_MASTER_CATEGORY_FETCH = '@assembly-category/fetch';
export const ASSEMBLY_MASTER_CATEGORY_FETCH_SUCCESS = '@assembly-category/fetch-success';
export const ASSEMBLY_MASTER_CATEGORY_FETCH_FAILURE = '@assembly-category/fetch-failure';


export function uploadAssemblyMaster(userId, assemblyMaster) {
  return async (dispatch) => {
    console.log("uploadAssemblyMaster():", userId, assemblyMaster);

    let newAssemblyMasterList = new AssemblyMaster(userId, assemblyMaster);
    newAssemblyMasterList.updatedBy = userId;

    console.log("uploadAssemblyMaster():" + JSON.stringify(assemblyMaster) + " " + JSON.stringify(newAssemblyMasterList));

    try {
      dispatch({ type: ASSEMBLY_MASTER_UPLOAD });

      const project = await masterService.uploadAssemblyMaster(newAssemblyMasterList);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: ASSEMBLY_MASTER_UPLOAD_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: ASSEMBLY_MASTER_UPLOAD_FAILURE });
      throw error;
    }
  };
}

export function fetchAssemblyMasterList() {
  return async (dispatch) => {
    console.log("fetchAssemblyMasterList():");

    try {
      dispatch({ type: ASSEMBLY_MASTER_FETCH });

      const list = await masterService.fetchAssemblyMasterList();

      console.log("fetchAssemblyMasterList()(after):" + JSON.stringify(list));
      dispatch({
        type: ASSEMBLY_MASTER_FETCH_SUCCESS,
        payload: {
          list
        }
      });
    } catch (error) {
      dispatch({ type: ASSEMBLY_MASTER_FETCH_FAILURE });
      throw error;
    }
  };
}

export function uploadAssemblyCategories(userId, assemblyMaster) {
  return async (dispatch) => {
    console.log("uploadAssemblyCategories():", userId, assemblyMaster);

    let newAssemblyMasterList = new AssemblyCategories(userId, assemblyMaster);
    newAssemblyMasterList.updatedBy = userId;

    console.log("uploadAssemblyCategories():" + JSON.stringify(assemblyMaster) + " " + JSON.stringify(newAssemblyMasterList));

    try {
      dispatch({ type: PROJECT_ASSEMBLY_MASTER_CATEGORY_UPLOAD });

      const project = await masterService.uploadAssemblyCategories(newAssemblyMasterList);

      //console.log("createProject()(after):" + JSON.stringify(project));
      dispatch({
        type: PROJECT_ASSEMBLY_MASTER_CATEGORY_UPLOAD_SUCCESS,
        payload: {
          project
        }
      });
    } catch (error) {
      dispatch({ type: PROJECT_ASSEMBLY_MASTER_CATEGORY_UPLOAD_FAILURE });
      throw error;
    }
  };
}

export function fetchAssemblyCategories() {
  return async (dispatch) => {
    console.log("fetchAssemblyCategories():");

    try {
      dispatch({ type: ASSEMBLY_MASTER_CATEGORY_FETCH });

      const list = await masterService.fetchAssemblyCategories();

      console.log("fetchAssemblyCategories()(after):" + JSON.stringify(list));
      dispatch({
        type: ASSEMBLY_MASTER_CATEGORY_FETCH_SUCCESS,
        payload: {
          list
        }
      });
    } catch (error) {
      dispatch({ type: ASSEMBLY_MASTER_CATEGORY_FETCH_FAILURE });
      throw error;
    }
  };
}
