export const CLIENT_LIST_URL = "/api/client/list";
export const CREATE_CLIENT_URL = "/api/client/create";
export const EDIT_CLIENT_URL = "/api/client/update";
export const DELETE_CLIENT_URL = "/api/client/delete";

export const STOCK_LIST_URL = "/api/stock/list";
export const IN_STOCK_LIST_URL = "/api/stock/in-list";
export const DELIVERED_STOCK_LIST_URL = "/api/stock/delivered-list";
export const EMPTY_STOCK_LIST_URL = "/api/stock/empty-list";
export const RETURNED_STOCK_LIST_URL="/api/stock/returned-list";
export const STOCK_IN_URL = "/api/stock/in";
export const STOCK_OUT_URL = "/api/stock/out";
export const EDIT_STOCK_URL = "/api/stock/edit";
export const DELETE_STOCK_URL = "/api/stock/delete";
export const STOCK_DETAILS_URL = "/api/stock/details";

export const STOCK_DELIVER_URL = "/api/stock/deliver";
export const STOCK_RETURN_URL = "/api/stock/return";

export const STOCK_SUMMARY_URL = "/api/dashboard/stock-summary";
