import axios from "src/utils/axios";

import {
    CLIENT_LIST_URL,
    CREATE_CLIENT_URL,
    EDIT_CLIENT_URL,
    DELETE_CLIENT_URL
} from "src/config/services";


class ClientService {


    createClient =(newClient) => {
        new Promise((resolve, reject) => {
            const payload = newClient;

            console.log("createClient(S) newClient: ", newClient);

            axios
            .post(CREATE_CLIENT_URL, { payload})
            .then((resp) => {
                let client = resp?.data?.payload;
                console.log("createClient(S) resp: ", resp);
                resolve(client);
            })
            .catch((err) => {
                console.log("ClientService.createClient(S) err: ", err);                
                reject(err);
            })
        })
    }

    editClient =(newClient) => {
        new Promise((resolve, reject) => {
            const payload = newClient;

            console.log("editClient(S) newClient: ", newClient);

            axios
            .post(EDIT_CLIENT_URL, { payload})
            .then((resp) => {
                let client = resp?.data?.payload;
                console.log("editClient(S) resp: ", resp);
                resolve(client);
            })
            .catch((err) => {
                console.log("ClientService.editClient(S) err: ", err);                
                reject(err);
            })
        })
    }

    deleteClient =(clientId) => {
        new Promise((resolve, reject) => {
            console.log("deleteClient(S) clientId: ", clientId);

            axios
            .post(DELETE_CLIENT_URL, { payload: {clientId}})
            .then((resp) => {
                // let client = resp?.data?.payload;
                console.log("deleteClient(S) resp: ", resp);
                resolve();
            })
            .catch((err) => {
                console.log("ClientService.createClient(S) err: ", err);                
                reject(err);
            })
        })
    }

    fetchClients = () =>
    new Promise((resolve, reject) => {


        axios
        .get(CLIENT_LIST_URL)
        .then((resp) => {
            const payload = resp?.data?.payload;
            console.log("ClientService.fetchClients() ", resp);

            resolve(payload?.clients);
        })
        .catch((err) => {
            console.log("ClientService.fetchClients() err: ", err);
            resolve([]);
            reject(err);
        })

    });



} 

const clientService = new ClientService();
export default clientService;
