/* eslint-disable no-param-reassign */
import produce from "immer";
import { suppliers } from "src/config/supplier";


const initialState = {
    suppliers: suppliers,
};


const supplierReducer = ( state = initialState, action) => {

    switch(action.type) {

        // case CLIENT_LIST_SUCCESS:
        //     {
        //         const clients = action.payload.clients;
        //         console.log("clientReducer: clients", clients);

        //         return produce(state, (draft) => {
        //             draft.clients = clients ? clients : [];
        //         });
        //     }

        default: {
            return state;
        }
    }



}
export default supplierReducer;