import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import clientReducer from './clientReducer';
import supplierReducer from './supplierReducer';


import assemblyReducer from './assemblyReducer';
import projectReducer from './projectReducer';
import stockReducer from './stockReducer';
import dashboardReducer from './dashboardReducer';


const rootReducer = combineReducers({
  account: accountReducer,
  client: clientReducer,
  supplier: supplierReducer,
  stock: stockReducer,
  dashboard: dashboardReducer,
  assembly: assemblyReducer,
  project: projectReducer,
  form: formReducer,
});

export default rootReducer;
