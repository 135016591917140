class Stock {
    constructor(data) {
        this.txnId = data.txnId;
        this.supplierId = data.supplierId;
        this.cylinderId = data.cylinderId;
        this.cylinderSize = data.cylinderSize;
        this.gasCode = data.gasCode;
        this.inStock = data.inStock;
        this.txnDate = data.txnDate
        this.refDoc = data.refDoc;
        this.remark = data.remark;
        this.addedBy = data.addedBy;
    }
}

class StockDelivery {
    constructor(data) {
        this.txnId = data.txnId;
        this.txnDate = data.txnDate;
        this.clientId = data.clientId;
        this.clientName = data.clientName;
        this.challanNo = data.challanNo;
        this.purchaseOrder = data.purchaseOrder;
        this.invoiceNo = data.invoiceNo;
        this.remark = data.remark;
        this.addedBy = data.addedBy;
    }
}

class StockOut {
    constructor(data) {
        this.txnId = data.txnId;
        this.txnDate = data.txnDate;
        this.clientId = data.clientId;
        this.clientName = data.clientName;
        this.refDoc = data.refDoc;
        this.remark = data.remark;
        this.addedBy = data.addedBy;
    }
}


export default { Stock };
export { Stock, StockDelivery, StockOut };