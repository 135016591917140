import { useRoutes } from 'react-router-dom';

// routes
import DashboardRoutes from './DashboardRoutes';
import AuthRoutes from './AuthRoutes';
import HomeRoutes from './HomeRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    //return useRoutes([MainRoutes, AuthenticationRoutes]);
    //return useRoutes([DashboardRoutes, AuthRoutes]);
    return useRoutes([HomeRoutes, AuthRoutes, DashboardRoutes]);
}
