import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SplashScreen from "src/components/SplashScreen";
import { setUserData, signout } from "src/actions/accountActions";
import authService from "src/services/authService";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(signout()),
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        //console.log("Auth|Before loginInWithToken ");

        const userId = authService.getUserId();

        //console.log("Auth|Before loginInWithToken :" + userId);

        const user = await authService.loginInWithToken(userId);
        await dispatch(setUserData(user));

        //console.log("Auth|After loginInWithToken " + JSON.stringify(user));

        // Fetch and Set all the configuration data on successful login
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
