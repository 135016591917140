/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@mui/material";
import typography from "./typography";
import { softShadows } from "./shadows";
import { THEMES } from "src/constants";

import { createTheme } from '@mui/material/styles';

const baseConfig = {
  direction: "ltr",
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
  },
};

const themeConfigs = [
  {
    name: THEMES.DEFAULT,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#1A659E", // colors.blueGrey[600]
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: "#1A659E", // colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white,
      },
      primary: {
        main: "#1A659E", //colors.indigo[600]
      },
      secondary: {
        main: "#1A659E", //'#5850EC'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.VIVSAC_BLUE,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#7B0828", //colors.blueGrey[600] //'#1A659E'
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: "#7B0828", // colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white,
      },
      primary: {
        main: "#4DAEEA", //colors.indigo[600]
      },
      secondary: {
        main: "#4DAEEA", //'#5850EC'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
];

export function createAppTheme(settings = {}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
