class Client {
    constructor(clientInfo) {
        this.clientId = clientInfo.clientId;
        this.businessName = clientInfo.businessName;
        this.contactName = clientInfo.contactName;
        this.email = clientInfo.email;
        this.mobile = clientInfo.mobile;
        this.addedBy = clientInfo.addedBy;
    }
}


export default { Client };
export { Client };