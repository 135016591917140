/* eslint-disable import/prefer-default-export */
export const THEMES = {
  DEFAULT: "DEFAULT",
  VIVSAC_BLUE: "VIVSAC_BLUE",

  /*
  TODO_SP: Delete this
  KHATA_CORNER: 'KHATA_CORNER',
  KHATA_CORNER_BLUE_JEANS: 'KHATA_CORNER_BLUE_JEANS',
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  KHATA_CORNER_BURGUNDY: 'KHATA_CORNER_BURGUNDY'

  */
};

export const USER_ROLES = {
  COACHEE: "COACHEE",
  COACH: "COACH",
  ADMIN: "ADMIN",
};

export const PROJECT_TYPES = [
  "ACQUISITION",
  "NEW CONSTRUCTION",
  "ACQUISITION IMPROVEMENTS",
];

export const PROJECT_TYPES_NEW_CONSTRUCTION = "NEW CONSTRUCTION";
export const PROJECT_TYPES_ACQUISITION = "ACQUISITION";

export const STUDY_TYPES = ["BASIC", "ADVANCE"];

export const PROPERTY_TYPES = [
  "Apartment",
  "Assisted Living",
  "Auto Dealership",
  "Bank, Branch Office",
  "Car / Truck Repair",
  "Car Wash",
  "Casino",
  "Cold Storage",
  "Distribution Center",
  "Food Processing Facility",
  "Golf Course/Country Club",
  "Hospital / Medical Center",
  "Hotel",
  "Industrial",
  "Lube/Oil Change Facility",
  "Manufacturing ",
  "Mixed Use (Apt & Other)",
  "Nursing Home",
  "Office - General",
  "Office - Medical",
  "Office Condo",
  "R & D Facility",
  "Restaurant",
  "Retail",
  "Self Storage Facility",
  "Service (Gas) Stations",
  "Supermarket",
  "Theater / Cinema",
  "Veterinary Facility",
  "Warehouse",
  "Residential",
  "Non Residential",
];

export const DEPRECIATION_TYPES = ["ADS", "GDS"];
export const DEPRECIATION_TYPE_ADS = "ADS";
export const DEPRECIATION_TYPE_GDS = "GDS";

export const PROPERTY_CONDITION = [
  "NEW",
  "EXCELLENT",
  "GOOD",
  "AVERAGE",
  "POOR",
];

export const UOMS = [
  "EA",
  "KG",
  "LF",
  "SF",
  "VLF",
];

export const PROPERTY_CONDITION_FACTOR = {
  NEW : 100,
  EXCELLENT: 80,
  GOOD: 60,
  AVERAGE: 40,
  POOR: 20,
};


export const ESTIMATE_TYPE = [
  "ACT",
  "EST",
];

export const TAKE_OFF_METHOD_TYPE = [
  "VARIANCE",
  "RESIDUAL",
  "ACTUAL",
  "DIRECT"
];

export const ASSET_CLASS_TYPE = [
  "57.1",
  "RP27.5",
  "RP39.0",
]


export const US_STATES = [
  "Alabama ",
  "Alaska ",
  "Arizona ",
  "Arkansas ",
  "California ",
  "Colorado ",
  "Connecticut ",
  "Delaware ",
  "Florida ",
  "Georgia ",
  "Hawaii ",
  "Idaho ",
  "Illinois ",
  "Indiana ",
  "Iowa ",
  "Kansas ",
  "Kentucky ",
  "Louisiana ",
  "Maine ",
  "Maryland ",
  "Massachusetts ",
  "Michigan ",
  "Minnesota ",
  "Mississippi ",
  "Missouri ",
  "Montana ",
  "Nebraska ",
  "Nevada ",
  "New Hampshire ",
  "New Jersey ",
  "New Mexico ",
  "New York ",
  "North Carolina ",
  "North Dakota ",
  "Ohio ",
  "Oklahoma ",
  "Oregon ",
  "Pennsylvania ",
  "Rhode Island ",
  "South Carolina ",
  "South Dakota ",
  "Tennessee ",
  "Texas ",
  "Utah ",
  "Vermont ",
  "Virginia ",
  "Washington ",
  "West Virginia ",
  "Wisconsin ",
  "Wyoming ",
];

export const INDIRECTS_COST_TYPES = [
  "Project Indirect",
  "Building Indirect",
  "Contract Indirect",
]

export const COST_TYPES = [
  "Direct",
  "Project Indirect",
  "Building Indirect",
  "Contract Indirect",
]

export const CREATE_PROJECT_URL = "/api/project/create";
export const UPDATE_PROJECT_URL = "/api/project/update";
export const UPDATE_PROPERTY_INFO_URL = "/api/project/propertyinfo/update";
export const CREATE_ASSET_URL = "/api/project/asset/create";
export const UPDATE_ASSET_URL = "/api/project/asset/update";
export const DELETE_ASSET_URL = "/api/project/asset/delete";
export const CREATE_TENANT_URL = "/api/project/asset/tenant/create";
export const CREATE_PROJECT_ANALYSIS_URL = "/api/project/asset/analysis/create";
export const DELETE_PROJECT_ANALYSIS_URL = "/api/project/asset/analysis/delete";
export const ADD_RSM_TO_PROJECT_ANALYSIS_URL = "/api/project/asset/analysis/rsm/add";
export const ADD_CPC_TO_PROJECT_ANALYSIS_URL = "/api/project/asset/analysis/cpc/add";
export const UPLOAD_PROJECT_RSM_URL = "/api/project/rsm/upload";
export const UPLOAD_PROJECT_CPC_URL = "/api/project/cpc/upload";
export const UPLOAD_PROJECT_INDIRECTS_URL = "/api/project/indirects/upload";

export const UPLOAD_ASSEMBLY_MASTER_URL = "/api/master/assembly/master/upload";
export const FETCH_ASSEMBLY_MASTER_URL = "/api/master/assembly/master/list";
export const UPLOAD_ASSEMBLY_MASTER_CATEGORY_URL = "/api/master/assembly/category/upload";
export const FETCH_ASSEMBLY_MASTER_CATEGORY_URL = "/api/master/assembly/categories";

export const CREATE_REPORT_DRAFT_URL = "/api/report/create";
export const UPDATE_REPORT_DRAFT_URL = "/api/report/update";
export const FETCH_DRAFT_REPORT_URL = "/api/report/draft";
export const FETCH_MY_REPORTS_URL = "/api/report/reports";
