/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ASSEMBLY_MASTER_FETCH,
  ASSEMBLY_MASTER_FETCH_SUCCESS,
  ASSEMBLY_MASTER_FETCH_FAILURE,
  ASSEMBLY_MASTER_CATEGORY_FETCH,
  ASSEMBLY_MASTER_CATEGORY_FETCH_SUCCESS,
  ASSEMBLY_MASTER_CATEGORY_FETCH_FAILURE
} from 'src/actions/masterAction';


const initialState = {
  assemblyMasterList: [],
  assemblyDescriptions: [],
  assemblyMasterCategories: [],
  assemblyCategory: [],
};

const assemblyReducer = (state = initialState, action) => {

  // console.log("assemblyReducer: action.type: ", action.type, "payload: ", action.payload);
  switch (action.type) {

    case ASSEMBLY_MASTER_FETCH: {
      return produce(state, (draft) =>{
          draft.assemblyMasterList = [];
      })
    }

    case ASSEMBLY_MASTER_FETCH_SUCCESS: {
      const assemblyMasterList = action.payload.list;

      // console.log("Reducer: ASSEMBLY_MASTER_FETCH_SUCCESS", action.payload);
      return produce(state, (draft) => {
        draft.assemblyMasterList = assemblyMasterList;
        draft.assemblyDescriptions = [];
        assemblyMasterList.map( item => {
          draft.assemblyDescriptions.push(item.description);
        });

      });
    }

    case ASSEMBLY_MASTER_FETCH_FAILURE: {
      return produce(state, (draft) => {
        // Maybe store error
        draft.assemblyMasterList = [];

      });
    }



    case ASSEMBLY_MASTER_CATEGORY_FETCH: {
      return produce(state, (draft) =>{
          draft.assemblyMasterCategories = [];
      })
    }

    case ASSEMBLY_MASTER_CATEGORY_FETCH_SUCCESS: {
      const assemblyMasterCategories = action.payload.list;

      // console.log("Reducer: ASSEMBLY_MASTER_FETCH_SUCCESS", action.payload);

      return produce(state, (draft) => {
        draft.assemblyMasterCategories = assemblyMasterCategories;
        draft.assemblyCategory = [];
        assemblyMasterCategories.map( item => {
          draft.assemblyCategory.push(item.name);
        });

      });
    }

    case ASSEMBLY_MASTER_CATEGORY_FETCH_FAILURE: {
      return produce(state, (draft) => {
        // Maybe store error
        draft.assemblyMasterCategories = [];

      });
    }

    default: {
      return state;
    }
  }
};

export default assemblyReducer;
