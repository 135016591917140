/* eslint-disable no-param-reassign */
import produce from "immer";

import {
    STOCK_LIST,
    STOCK_LIST_SUCCESS,
    STOCK_LIST_FAILED,
    IN_STOCK_LIST_SUCCESS,
    DELIVERED_STOCK_LIST_SUCCESS,
    EMPTY_STOCK_LIST_SUCCESS,
} from "src/actions/stockActions";

const initialState = {
    stocks: [],
    inStocks: [],
    deliveredStocks: [],
    emptyStocks: []
};


const stockReducer = (state = initialState, action) => {

    switch (action.type) {

        case STOCK_LIST_SUCCESS:
            {
                const stocks = action.payload.stocks;
                console.log("stockReducer: stocks", stocks);

                return produce(state, (draft) => {
                    draft.stocks = stocks ? stocks : [];
                });
            }

        case IN_STOCK_LIST_SUCCESS:
            {
                const inStocks = action.payload.stocks;
                console.log("stockReducer: inStocks", inStocks);

                return produce(state, (draft) => {
                    draft.inStocks = inStocks ? inStocks : [];
                });
            }

        case DELIVERED_STOCK_LIST_SUCCESS:
            {
                const deliveredStocks = action.payload.stocks;
                console.log("stockReducer: deliveredStocks", deliveredStocks);

                return produce(state, (draft) => {
                    draft.deliveredStocks = deliveredStocks ? deliveredStocks : [];
                });
            }

        case EMPTY_STOCK_LIST_SUCCESS:
            {
                const emptyStocks = action.payload.stocks;
                console.log("stockReducer: emptyStocks", emptyStocks);

                return produce(state, (draft) => {
                    draft.emptyStocks = emptyStocks ? emptyStocks : [];
                });
            }




        default: {
            return state;
        }
    }
}
export default stockReducer;