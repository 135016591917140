import axios from "src/utils/axios";
import authService from "src/services/authService";

import {fetchAssemblyMasterList, fetchAssemblyCategories } from "src/actions/masterAction"

import { NewUserReqInfo } from "src/interface/authIf";
import { fetchClients } from "./clientActions";
//import { BusinessInfo, BusinessAccountInfo, BusinessTaxInfo, BusinessUserInfo, BusinessUserList, BusinessLogo } from 'src/interface/businessIf';
//import { UserProfileUpdate, PasswordInfo } from 'src/interface/authIf';

export const SIGNUP = "@auth/signup";
export const SIGNUP_SUCCESS = "@auth/signup-success";
export const SIGNUP_FAILURE = "@auth/signup-failure";

export const SIGNIN = "@auth/signin";
export const SIGNIN_SUCCESS = "@auth/signin-success";
export const SIGNIN_FAILURE = "@auth/signin-failure";

export const SIGNOUT = "@auth/signout";


export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const SILENT_LOGIN = "@account/silent-login";
export const LOGOUT = "@account/logout";
export const REGISTER = "@account/register";
export const UPDATE_PROFILE = "@account/update-profile";
export const UPDATE_PROFILE_SUCCESS = "@account/update-profile-success";
export const UPDATE_PROFILE_FAILED = "@account/update-profile-failed";

export const CHANGE_PASSWORD = "@account/change-password";
export const CHANGE_PASSWORD_SUCCESS = "@account/change-password-success";
export const CHANGE_PASSWORD_FAILED = "@account/change-password-failure";

export function signup(userInfo) {
  return async (dispatch) => {
    const newUserInfo = new NewUserReqInfo(userInfo);

    console.log("Account Actions: Signup:", userInfo, newUserInfo);

    try {
      dispatch({ type: SIGNUP });

      const email = await authService.signup(newUserInfo);
      console.log("Account Actions: Signup Resp:", email);

      dispatch({
        type: SIGNUP_SUCCESS,
        payload: {
          email
        },
      });
    } catch (error) {
      console.log("Account Actions: Error ", error);
      dispatch({ type: SIGNUP_FAILURE });
      throw error;
    }
  };
}

export function signin(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: SIGNIN });
      console.log("Account Actions: Signin:", email, password);
      const user = await authService.signinWithEmailAndPassword(email, password);

      dispatch({
        type: SIGNIN_SUCCESS,
        payload: {
          user,
        },
      });

      // Fetch Rest of the information for the user to initialize the application
      console.log("AccountAction-Login |Before fetching Assembly Master ");
      await dispatch(fetchClients());

      await dispatch(fetchAssemblyCategories());
      await dispatch(fetchAssemblyMasterList());
      console.log("AccountAction-Login | After Assembly");


    } catch (error) {
      console.log("authAction:signinWithEmailAndPassword:Error2", error);
      dispatch({ type: SIGNIN_FAILURE });
      throw error;
    }
  };
}

export function signout() {
  return async (dispatch) => {
    authService.signout();

    dispatch({
      type: SIGNOUT,
    });
  };
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
}

/*

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {

  const businessId = "";
  const email = "";

  let userInfo = new UserProfileUpdate(businessId, email, update);
  console.log("updateProfile(Action):", userInfo);

  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PROFILE});
      //console.log("updateProfile(dispatch):", userInfo);
      const userData = await authService.updateProfile(userInfo);
      //console.log("updateProfile(Action->):", userData);
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: {userData}
      });
    }catch (error) {
      dispatch({
        type: UPDATE_PROFILE_FAILED,
        payload: {}
      });
      throw error;
    }
  }
}

export function changePassword(businessId, email, update) {
  let passInfo = new PasswordInfo(businessId, email, update);
  //console.log("changePassword(Action):", passInfo);

  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD});
      const passData = await authService.changePassword(passInfo);
      //console.log("changePassword(Action->):", passData);
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: { passData}
      });
    }catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        payload: {}
      });
      throw error;
    }
  }
}



*/
