// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const REACT_APP_NAME = "admin";
export const APP_VERSION = '1.0.0';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;

export default {};

export const BUSINESS_TYPES = {
    GENERAL: 'general',
}

export const USER_ROLES = {
    ENGINEER: 'ENGINEER',
    SUPERVISOR: 'SUPERVISOR',
    ADMIN: 'ADMIN',
}
