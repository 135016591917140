import dashboardService from "src/services/dashboardService";


export const DASHBOARD_STOCK_SUMMARY = "@dashboard/stock-summary";
export const DASHBOARD_STOCK_SUMMARY_SUCCESS = "@dashboard/stock-summary-success";
export const DASHBOARD_STOCK_SUMMARY_FAILED = "@dashboard/stock-summary-failed";


export function fetchStockSummary() {
    return async (dispatch) => {

        try {
            dispatch({ type: DASHBOARD_STOCK_SUMMARY });

            const summary = await dashboardService.fetchStockSummary();

            dispatch({
                type: DASHBOARD_STOCK_SUMMARY_SUCCESS,
                payload: { summary }
            });
        } catch (err) {
            dispatch({ type: DASHBOARD_STOCK_SUMMARY_FAILED });
            throw err;
        }
    };
}
