import axios from "src/utils/axios";

import {
    STOCK_SUMMARY_URL,
} from "src/config/services";


class DashboardService {
    
    fetchStockSummary = () =>
        new Promise((resolve, reject) => {
            axios
                .get(STOCK_SUMMARY_URL)
                .then((resp) => {
                    const payload = resp?.data?.payload;
                    console.log("stockSummary(S) resp: ", resp);

                    resolve(payload?.summary);
                })
                .catch((err) => {
                    console.log("stockSummary(S) err: ", err);
                    resolve([]);
                    reject(err);
                })

        });

}

const dashboardService = new DashboardService();
export default dashboardService;
