import ApartmentIcon from '@mui/icons-material/Apartment';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import CalculateIcon from '@mui/icons-material/Calculate';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EngineeringIcon from '@mui/icons-material/Engineering';

import {
    // Truck as TruckIcon,
    // Briefcase as BriefcaseIcon,
    // Calendar as CalendarIcon,
    // ShoppingCart as ShoppingCartIcon,
    // Folder as FolderIcon,
    // BarChart as BarChartIcon,
    // Lock as LockIcon,
    // UserPlus as UserPlusIcon,
    // Shield as ShieldIcon,
    // AlertCircle as AlertCircleIcon,
    // Trello as TrelloIcon,
    // User as UserIcon,
    // Layout as LayoutIcon,
    // Edit as EditIcon,
    // DollarSign as DollarSignIcon,
    // Mail as MailIcon,
    // MessageCircle as MessageCircleIcon,
    PieChart as PieChartIcon,
    // Share2 as ShareIcon,
    Users as UsersIcon,
  } from "react-feather";

import { BUSINESS_TYPES, USER_ROLES } from 'src/config';

const UserNavConfig = [
    {
      subheader: "Dashboard",
      businesstype: [BUSINESS_TYPES.GENERAL],
      items: [
        {
          title: "Dashboard",
          icon: PieChartIcon,
          href: "/app/management/dashboard",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        }
      ],
    },
    {
      subheader: "Inventory Management",
      businesstype: [BUSINESS_TYPES.GENERAL],
      items: [
        {
          title: "Client Delivery",
          icon: ApartmentIcon,
          href: "/app/management/stocks/delivery",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
        {
          title: "Stocks",
          icon: ApartmentIcon,
          href: "/app/management/stocks",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
        {
          title: "Client Returns",
          icon: ApartmentIcon,
          href: "/app/management/stocks/return",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
        {
          title: "Refill",
          icon: ApartmentIcon,
          href: "/app/management/stocks/out",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
      ],
    },
    {
      subheader: "Management",
      businesstype: [BUSINESS_TYPES.GENERAL],
      items: [
        {
          title: "Clients",
          icon: ApartmentIcon,
          href: "/app/management/clients",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
        {
          title: "Suppliers",
          icon: ApartmentIcon,
          href: "/app/management/suppliers",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },        
      ],
    },
 
  ];

  export default UserNavConfig;