import { lazy } from 'react';

// project imports
import Loadable from 'src/components/Loadable';
import MinimalLayout from 'src/layouts/MinimalLayout';
import HomePageLayout from "src/layouts/HomePageLayout";

// login option 3 routing
//const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
//const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

const HomeView = Loadable(lazy(() => import("src/views/pages/HomeView")));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const HomeRoutes = {
    path: '/',
    element: <HomePageLayout />,
    children: [
        {
            //path: '/home',
            path: '/',
            element: <HomeView />
        }
      //  {
      //    path: '/',
      //    element: () => <Navigate to "/home" />
      //  },
    ]
};

export default HomeRoutes;
