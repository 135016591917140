import axios from "src/utils/axios";

import {
    STOCK_LIST_URL,
    STOCK_IN_URL,
    EDIT_STOCK_URL,
    DELETE_STOCK_URL,
    IN_STOCK_LIST_URL,
    STOCK_DELIVER_URL,
    STOCK_RETURN_URL,
    DELIVERED_STOCK_LIST_URL,
    EMPTY_STOCK_LIST_URL,
    STOCK_OUT_URL,
    RETURNED_STOCK_LIST_URL,
    STOCK_DETAILS_URL
} from "src/config/services";


class StockService {
    stockIn = (newStock) => {
        new Promise((resolve, reject) => {
            const payload = newStock;

            console.log("stockIn(S) newStock: ", newStock);

            axios
                .post(STOCK_IN_URL, { payload })
                .then((resp) => {
                    let client = resp?.data?.payload;
                    console.log("stockIn(S) resp: ", resp);
                    resolve(client);
                })
                .catch((err) => {
                    console.log("stockIn(S) err: ", err);
                    reject(err);
                })
        })
    }

    editStock = (newStock) => {
        new Promise((resolve, reject) => {
            const payload = newStock;

            console.log("editStock(S) newStock: ", newStock);

            axios
                .post(EDIT_STOCK_URL, { payload })
                .then((resp) => {
                    let client = resp?.data?.payload;
                    console.log("editStock(S) resp: ", resp);
                    resolve(client);
                })
                .catch((err) => {
                    console.log("editStock(S) err: ", err);
                    reject(err);
                })
        })
    }

    deleteStock = (txnId) => {
        new Promise((resolve, reject) => {
            console.log("deleteStock(S) txnId: ", txnId);

            axios
                .post(DELETE_STOCK_URL, { payload: { txnId } })
                .then((resp) => {
                    // let client = resp?.data?.payload;
                    console.log("deleteStock(S) resp: ", resp);
                    resolve();
                })
                .catch((err) => {
                    console.log("deleteStock(S) err: ", err);
                    reject(err);
                })
        })
    }

    fetchStocks = () =>
        new Promise((resolve, reject) => {
            axios
                .get(STOCK_LIST_URL)
                .then((resp) => {
                    const payload = resp?.data?.payload;
                    console.log("fetchStocks(S) resp: ", resp);

                    resolve(payload?.stocks);
                })
                .catch((err) => {
                    console.log("fetchStocks(S) err: ", err);
                    resolve([]);
                    reject(err);
                })

        });

    fetchInStocks = () =>
        new Promise((resolve, reject) => {
            axios
                .get(IN_STOCK_LIST_URL)
                .then((resp) => {
                    const payload = resp?.data?.payload;
                    console.log("fetchInStocks(S) resp: ", resp);

                    resolve(payload?.stocks);
                })
                .catch((err) => {
                    console.log("fetchInStocks(S) err: ", err);
                    resolve([]);
                    reject(err);
                })

        });

    // .get(`/api/project/list?emailId=${account.user.email}`)

    fetchDeliveredStocks = (clientId) =>
        new Promise((resolve, reject) => {
            const url = `${DELIVERED_STOCK_LIST_URL}?clientId=${clientId}`;
            axios
                .get(url)
                .then((resp) => {
                    const payload = resp?.data?.payload;
                    console.log("fetchDeliveredStocks(S) resp: ", resp);
                    resolve(payload?.stocks);
                })
                .catch((err) => {
                    console.log("fetchDeliveredStocks(S) err: ", err);
                    resolve([]);
                    reject(err);
                })

        });


    fetchEmptyStocks = () =>
        new Promise((resolve, reject) => {
            axios
                .get(EMPTY_STOCK_LIST_URL)
                .then((resp) => {
                    const payload = resp?.data?.payload;
                    console.log("fetchEmptyStocks(S) resp: ", resp);

                    resolve(payload?.stocks);
                })
                .catch((err) => {
                    console.log("fetchEmptyStocks(S) err: ", err);
                    resolve([]);
                    reject(err);
                })

        });

    fetchReturnedStocks = (clientId) =>
        new Promise((resolve, reject) => {

            const url = `${RETURNED_STOCK_LIST_URL}?clientId=${clientId}`;

            axios
                .get(url)
                .then((resp) => {
                    const payload = resp?.data?.payload;
                    console.log("fetchReturnedStocks(S) resp: ", resp);

                    resolve(payload?.stocks);
                })
                .catch((err) => {
                    console.log("fetchReturnedStocks(S) err: ", err);
                    resolve([]);
                    reject(err);
                })

        });

    fetchStockDetails = (txnId) =>
        new Promise((resolve, reject) => {

            const url = `${STOCK_DETAILS_URL}?txnId=${txnId}`;

            axios
                .get(url)
                .then((resp) => {
                    const payload = resp?.data?.payload;
                    console.log("fetchStockDetails(S) resp: ", resp);

                    resolve(payload);
                })
                .catch((err) => {
                    console.log("fetchStockDetails(S) err: ", err);
                    resolve([]);
                    reject(err);
                })

        });

    deliverStock = (stockData) => {
        new Promise((resolve, reject) => {
            const payload = stockData;
            console.log("deliverStock(S) stockData: ", stockData);

            axios
                .post(STOCK_DELIVER_URL, { payload })
                .then((resp) => {
                    let stock = resp?.data?.payload;
                    console.log("deliverStock(S) resp: ", resp);
                    resolve(stock);
                })
                .catch((err) => {
                    console.log("deliverStock(S) err: ", err);
                    reject(err);
                })
        })
    }

    returnStock = (stockData) => {
        new Promise((resolve, reject) => {
            const payload = stockData;
            console.log("returnStock(S) stockData: ", stockData);

            axios
                .post(STOCK_RETURN_URL, { payload })
                .then((resp) => {
                    let stock = resp?.data?.payload;
                    console.log("returnStock(S) resp: ", resp);
                    resolve(stock);
                })
                .catch((err) => {
                    console.log("returnStock(S) err: ", err);
                    reject(err);
                })
        })
    }

    outStock = (stockData) => {
        new Promise((resolve, reject) => {
            const payload = stockData;
            console.log("outStock(S) stockData: ", stockData);

            axios
                .post(STOCK_OUT_URL, { payload })
                .then((resp) => {
                    let stock = resp?.data?.payload;
                    console.log("outStock(S) resp: ", resp);
                    resolve(stock);
                })
                .catch((err) => {
                    console.log("outStock(S) err: ", err);
                    reject(err);
                })
        })
    }
}

const stockService = new StockService();
export default stockService;
