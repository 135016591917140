/* eslint-disable no-param-reassign */
import produce from "immer";

import {
    CLIENT_LIST,
    CLIENT_LIST_SUCCESS,
    CLIENT_LIST_FAILED,
} from "src/actions/clientActions";

const initialState = {
    clients: [],
};


const clientReducer = ( state = initialState, action) => {

    switch(action.type) {

        case CLIENT_LIST_SUCCESS:
            {
                const clients = action.payload.clients;
                console.log("clientReducer: clients", clients);

                return produce(state, (draft) => {
                    draft.clients = clients ? clients : [];
                });
            }

        default: {
            return state;
        }
    }



}
export default clientReducer;