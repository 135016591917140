import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Outlet } from 'react-router-dom';
import { Box, Grid } from "@mui/material";
import CopyRight from "src/components/CopyRight";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <Box >
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Grid container sx={{
        height: 'calc(100vh - 64px)', // Adjusted for a header with 64px height
        ml: { xs: '0px', sm: '0px', md: '256px', lg: '256px' },
        width: 'calc(100% - 256px)',
        display: 'flex',
        paddingTop: '64px',
        justifyContent: 'flex-start',
      }}>
        {/* <main> */}
        {children}
        <Outlet />
        {/* </main> */}
      </Grid>
      {/* <Box item width="100%" display="flex" alignItems="flex-end" >
        <CopyRight />
      </Box> */}

    </Box>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
};



export default DashboardLayout;
