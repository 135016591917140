// Define constants for suppliers
export const suppliers = [
    { 
      supplierId: 1, 
      supplierName: "Gorakhram Haribux", 
      supplierAddress: "Plot No. 35, Phase -II, Government Industrial Extate, Piparia, Silvasa - 396230",
      supplierEmail: "gorakhramharibux@allstate.in",
      supplierContact: "7738623000"
     },
     { 
      supplierId: 2, 
      supplierName: "SRF LTD", 
      supplierAddress: "The Galleria, DLF Mayur Vihar, Unit No. 236 & 237,Second Floor, Mayur Vihar Phase I Extn, Delhi -110091",
      supplierEmail: "piyush.bangur@srf.com",
      supplierContact: "9898070122"
     },
    // Add more suppliers as needed
  ];
  
// Function to get supplier name from supplierId
export const getSupplierFromId = (suppliers, id) => {
  const supplier = suppliers.find((s) => s.supplierId === id);
  return supplier ? supplier : null;
};


  // Function to get supplier name from supplierId
  export const getSupplierNameFromId = (suppliers, id) => {
    const supplier = suppliers.find((s) => s.supplierId === id);
    return supplier ? supplier.supplierName : "Supplier Not Found";
  };
  
  // Function to get supplierId from supplier name
  export const getSupplierIdFromName = (suppliers, name) => {
    const supplier = suppliers.find((s) => s.supplierName === name);
    return supplier ? supplier.supplierId : "Supplier Not Found";
  };
  
  // Example usage
//   console.log(getSupplierNameFromId(1)); // Output: Supplier A
//   console.log(getSupplierIdFromName("Supplier B")); // Output: 2
  