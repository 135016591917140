/* eslint-disable no-param-reassign */
import produce from "immer";

import {
    DASHBOARD_STOCK_SUMMARY,
    DASHBOARD_STOCK_SUMMARY_SUCCESS
} from "src/actions/dashboardAction";

const initialState = {
    stockSummary: undefined,
};


const dashboardReducer = (state = initialState, action) => {

    switch (action.type) {

        case DASHBOARD_STOCK_SUMMARY_SUCCESS:
            {
                const stockSummary = action.payload.summary;
                console.log("dashboardReducer: stockSummary", stockSummary);

                return produce(state, (draft) => {
                    draft.stockSummary = stockSummary;
                });
            }

        default: {
            return state;
        }
    }
}
export default dashboardReducer;